<template>
    <Header/>
        <Banner name="Partnership"  message="Partner with us." />
        <div class="section section-padding mt-n1">
            <div class="container">
                <!-- Section Title Start -->
                <div class="section-title shape-03 text-center">
                    <h2 class="main-title header-text"><span>Learn a Vocational Skill</span></h2>
                </div>
                <!-- Section Title End -->

                <!-- Blog Wrapper Start -->
                <div class="blog-wrapper">
                    <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-lg-9">
                            <iframe
                                width="100%"
                                height="600"
                                :src="erp_link"
                                frameborder="0"
                                allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import SingleEvent from '@/components/Events/SingleEvent.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default{
    name:"EventShow",
    components:{
        Header,Banner,Footer,SingleEvent
    },
    data() {
        return {
            erp_link: "https://bhub.thrivecloud.ng/learn-a-vocational-skill"
        }
    },
}
</script>